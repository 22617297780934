<template>
  <div class="enclosureBox">
    <div class="enclosureList">
      <div class="typeList">
        <el-radio-group v-model="nowAddEnclosure">
          <el-radio v-for="(item, index) in enclosureType" :key="index" :label="item.value">{{
            item.name
          }}</el-radio>
        </el-radio-group>
        <el-button type="text" @click="addEnclosure({ value: nowAddEnclosure })"
          >添加附件</el-button
        >
      </div>
      <div class="tips" v-if="nowUpdateIndex !== null">
        当前正在修改第{{ nowUpdateIndex + 1 }}个附件
      </div>
      <div class="enclosureItem" v-if="nowUpdateItem">
        <div class="content">
          <div class="contentItem">
            <div class="contentTitle">附件类型：</div>
            <div class="contentBoX">
              {{
                nowUpdateItem.msgtype === 'image'
                  ? '图片附件'
                  : nowUpdateItem.msgtype === 'link'
                  ? '图文附件'
                  : nowUpdateItem.msgtype === 'miniprogram'
                  ? '小程序附件'
                  : nowUpdateItem.msgtype === 'video'
                  ? '视频附件'
                  : nowUpdateItem.msgtype === 'file'
                  ? '文件附件'
                  : ''
              }}
            </div>
          </div>
        </div>
        <div class="content" v-if="nowUpdateItem.msgtype === 'image'">
          <div class="contentItem">
            <div class="contentTitle">附件内容：</div>
            <div class="contentBoX">
              <ReadyUploadSource
                @getSource="(val) => getImage(val, nowUpdateItem, 0)"
                @removeThis="removeImage(nowUpdateItem, 0)"
                :path="nowUpdateItem.image.pic_url"
                :maxSize="1024 * 1024 * 2"
                type="live"
                :url="3"
                :temporary_type="1"
              ></ReadyUploadSource>
              <div class="tips">只能上传jpg、jpeg、png三种格式，大小不能超过2MB</div>
            </div>
          </div>
        </div>
        <div class="content" v-if="nowUpdateItem.msgtype === 'video'">
          <div class="contentItem">
            <div class="contentTitle">附件内容：</div>
            <div class="contentBoX">
              <ReadyUploadSource
                @getSource="(val) => getImage(val, nowUpdateItem, 1)"
                @removeThis="removeImage(nowUpdateItem, 1)"
                :path="nowUpdateItem.video.path"
                :maxSize="1024 * 1024 * 10"
                type="liveVideo"
                :url="3"
                :temporary_type="1"
              ></ReadyUploadSource>
              <div class="tips">只能上传MP4格式的视频，大小不能超过10MB</div>
            </div>
          </div>
        </div>
        <div class="content" v-if="nowUpdateItem.msgtype === 'link'">
          <div class="contentItem">
            <div class="contentTitle">图文链接：</div>
            <div class="contentBoX">
              <el-input
                v-model.trim="nowUpdateItem.link.url"
                placeholder="请输入http或者https开头的链接"
                @blur="chenkHeader"
                :spellcheck="false"
                :temporary_type="1"
              ></el-input>
            </div>
          </div>
          <div class="contentItem">
            <div class="contentTitle">图文标题：</div>
            <div class="contentBoX">
              <el-input
                v-model.trim="nowUpdateItem.link.title"
                placeholder="请输入网页标题"
                maxlength="50"
                show-word-limit
                :spellcheck="false"
                :temporary_type="1"
              ></el-input>
            </div>
          </div>
          <div class="contentItem">
            <div class="contentTitle">图文描述：</div>
            <div class="contentBoX">
              <el-input
                type="textarea"
                v-model="nowUpdateItem.link.desc"
                placeholder="请输入150字以内的网页描述"
                maxlength="150"
                show-word-limit
                :rows="4"
                :spellcheck="false"
                :temporary_type="1"
              ></el-input>
            </div>
          </div>
          <div class="contentItem">
            <div class="contentTitle">图文封面：</div>
            <div class="contentBoX">
              <ReadyUploadSource
                @getSource="(val) => getLink(val, nowUpdateItem)"
                @removeThis="removeLink(nowUpdateItem)"
                :path="nowUpdateItem.link.picurl"
                :maxSize="1024 * 1024 * 2"
                :temporary_type="1"
              ></ReadyUploadSource>
              <div class="tips">只能上传jpg、jpeg、png三种格式，大小不能超过2MB</div>
            </div>
          </div>
        </div>
        <div class="content" v-if="nowUpdateItem.msgtype === 'miniprogram'">
          <div class="contentItem">
            <div class="contentTitle">小程序AppId：</div>
            <div class="contentBoX">
              <el-input
                v-model.trim="nowUpdateItem.miniprogram.appid"
                placeholder="请输入小程序AppId"
              ></el-input>
            </div>
          </div>
          <div class="contentItem">
            <div class="contentTitle">页面路径：</div>
            <div class="contentBoX">
              <el-input
                v-model.trim="nowUpdateItem.miniprogram.page"
                placeholder="请输入小程序打开后的页面路径"
              ></el-input>
            </div>
          </div>
          <div class="contentItem">
            <div class="contentTitle">小程序标题：</div>
            <div class="contentBoX">
              <el-input
                v-model.trim="nowUpdateItem.miniprogram.title"
                placeholder="请输入小程序标题"
                maxlength="50"
                show-word-limit
                :spellcheck="false"
              ></el-input>
            </div>
          </div>
          <div class="contentItem">
            <div class="contentTitle">小程序封面：</div>
            <div class="contentBoX">
              <ReadyUploadSource
                @getSource="(val) => getMiniPic(val, nowUpdateItem)"
                @removeThis="removeMiniPic(nowUpdateItem)"
                :path="nowUpdateItem.miniprogram.path"
                :maxSize="1024 * 1024 * 2"
                type="live"
                :url="3"
                :temporary_type="1"
              ></ReadyUploadSource>
              <div class="tips">只能上传jpg、jpeg、png三种格式，大小不能超过2MB</div>
            </div>
          </div>
        </div>
        <div class="content" v-if="nowUpdateItem.msgtype === 'file'">
          <div class="contentItem">
            <div class="contentTitle">附件文件：</div>
            <div class="contentBoX">
              <div class="fileBox">
                <div class="fileName" v-if="nowUpdateItem.file.path">
                  {{ nowUpdateItem.file.path }}
                </div>
                <el-button
                  type="text"
                  v-if="nowUpdateItem.file.path"
                  @click="copyPath($event, nowUpdateItem)"
                  >复制</el-button
                >
                <el-button type="primary" size="small" @click="openUploadFile">上传文件</el-button>
              </div>
              <div class="tips">图片（image）：10MB，支持JPG,PNG格式</div>
              <div class="tips">语音（voice） ：2MB，播放长度不超过60s，仅支持AMR格式</div>
              <div class="tips">视频（video） ：10MB，支持MP4格式</div>
              <div class="tips">普通文件（file）：20MB</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UploadSource
      v-if="fileUploadFlag"
      :dialogVisible="fileUploadFlag"
      @changeStatus="changeStatus"
      type="file"
      :url="3"
      @getSource="getFileInfo"
      :temporary_type="1"
    ></UploadSource>
  </div>
</template>

<script>
import clipboard from '@/util/clipBoard.js'
import ReadyUploadSource from '@/components/readyUploadSource'
import UploadSource from '@/components/uploadSource'
import { bus } from '@/util/bus'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    nowUpdateItem: {
      type: Object,
      default: () => {}
    },
    nowAddEnclosure: {
      type: String,
      default: 'image'
    },
    nowUpdateIndex: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ReadyUploadSource,
    UploadSource
  },

  data() {
    return {
      enclosureType: [
        { id: 1, name: '图片', value: 'image', tips: '点击添加一个图片类型的附件' },
        { id: 2, name: '图文', value: 'link', tips: '点击添加一个图文类型的附件' },
        { id: 3, name: '小程序', value: 'miniprogram', tips: '点击添加一个小程序类型的附件' },
        { id: 4, name: '视频', value: 'video', tips: '点击添加一个视频类型的附件' },
        { id: 5, name: '文件', value: 'file', tips: '点击添加一个文件类型的附件' }
      ],
      fileUploadFlag: false
    }
  },
  methods: {
    // 添加一个新的附件
    addEnclosure(item) {
      if (this.data.length >= 9) {
        this.$message.warning('最多只能添加九个附件')
        return
      }
      let imageObj = {
        msgtype: 'image',
        image: {
          media_id: null,
          pic_url: null
        }
      }
      let linkObj = {
        msgtype: 'link',
        link: {
          title: '',
          picurl: null,
          desc: '',
          url: ''
        }
      }
      let miniprogramObj = {
        msgtype: 'miniprogram',
        miniprogram: {
          path: null,
          title: '',
          pic_media_id: null,
          appid: '',
          page: ''
        }
      }
      let videoObj = {
        msgtype: 'video',
        video: {
          path: '',
          media_id: '',
          name: ''
        }
      }
      let fileObj = {
        msgtype: 'file',
        file: {
          media_id: '',
          showSize: ''
        }
      }
      if (item.value == 'image') {
        this.data.push(imageObj)
      } else if (item.value == 'link') {
        this.data.push(linkObj)
      } else if (item.value == 'miniprogram') {
        this.data.push(miniprogramObj)
      } else if (item.value == 'video') {
        this.data.push(videoObj)
      } else if (item.value == 'file') {
        this.data.push(fileObj)
      }
      this.nowUpdateItem = this.data[this.data.length - 1]
      bus.$emit('changeNowUpdate', this.nowUpdateItem, this.data.length - 1)
    },
    // 获取图片附件
    getImage(val, item, status) {
      if (!status) {
        this.$set(item.image, 'pic_url', val.path)
        this.$set(item.image, 'media_id', val.media_id)
      } else if (status === 1) {
        this.$set(item.video, 'path', val.path)
        this.$set(item.video, 'media_id', val.media_id)
      }
    },
    // 清空图片组件
    removeImage(item, status) {
      if (!status) {
        this.$set(item.image, 'pic_url', null)
        this.$set(item.image, 'media_id', null)
      } else if (status === 1) {
        this.$set(item.video, 'path', null)
        this.$set(item.video, 'media_id', null)
      }
    },
    getLink(val, item) {
      this.$set(item.link, 'picurl', val.path)
      this.$set(item.link, 'media_id', val.media_id)
    },
    removeLink(item) {
      this.$set(item.link, 'path', null)
      this.$set(item.link, 'media_id', null)
    },
    getMiniPic(val, item) {
      this.$set(item.miniprogram, 'path', val.path)
      this.$set(item.miniprogram, 'pic_media_id', val.media_id)
    },
    removeMiniPic(item) {
      this.$set(item.miniprogram, 'path', null)
      this.$set(item.miniprogram, 'pic_media_id', null)
    },
    changeStatus(val) {
      this.fileUploadFlag = val
    },
    openUploadFile() {
      this.fileUploadFlag = true
    },
    getFileInfo(val) {
      this.nowUpdateItem.file.path = val[0].path
      this.nowUpdateItem.file.media_id = val[0].media_id
      this.nowUpdateItem.file.showSize = val[0].showSize
      this.nowUpdateItem.file.name = val[0].name
    },
    copyPath(e, item) {
      clipboard(e, item.path)
    },
    // 检测图文附件link是否带有https://
    chenkHeader() {
      let strThis = this.nowUpdateItem.link.url
      let res = 'https://'
      let res1 = 'http://'
      let str = strThis.substring(0, res.length)
      let str1 = strThis.substring(0, res1.length)
      console.log(str, str1)
      if (str !== res && str1 !== res1) {
        this.$set(this.nowUpdateItem.link, 'url', 'https://' + strThis)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tips {
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9f;
  line-height: 12px;
  margin: 3px 0;
}

.enclosureBox {
  .typeList {
    display: flex;
    height: 40px;

    .el-radio-group {
      display: flex;
      align-items: center;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .enclosureList {
    .enclosureItem {
      border: 1px dashed #e4e4e4;
      margin-bottom: 5px;
      padding-left: 20px;
      .content {
        .contentItem {
          display: flex;
          margin-bottom: 10px;
          .contentTitle {
            width: 100px;
            text-align: right;
          }
          .el-input,
          .el-textarea {
            width: 300px;
          }
          .contentBoX {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .fileBox {
              display: flex;
              align-items: center;
              .fileName {
                width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
</style>
